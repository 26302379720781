export const MEALION_URL = 'https://mealion.kr';

export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.connple.mealion';
export const APP_STORE_URL = 'https://apps.apple.com/kr/app/1542012254';
export const KAKAO_CS_URL = 'http://pf.kakao.com/_xbuxhxgb/chat';

export const CREATOR_FORM = 'https://the-form.io/forms/survey/response/a732ab77-64ff-488d-aa2c-d11960bc0364';

export const MEALION_INFO_URL = 'https://bit.ly/33tEMoY?utm_source=web'; // 회사소개
export const MEALION_INFO_URL_MOBILE = 'https://bit.ly/33tEMoY';

// social media
// 블로그
export const BLOG_URL = 'https://blog.naver.com/mealion_official?utm_source=web';
export const BLOG_URL_MOBILE = 'https://blog.naver.com/mealion_official';
// 인스타그램
export const INSTAGRAM_URL = 'https://www.instagram.com/mealion_official/?utm_source=web';
export const INSTAGRAM_URL_MOBILE = 'https://www.instagram.com/mealion_official/';
// 페이스북
export const FACEBOOK_URL = 'https://www.facebook.com/mealionKR/';
export const FACEBOOK_URL_MOBILE = 'https://www.facebook.com/mealionKR/?utm_source=web';
// 약관
export const PRIVACY_CONSENT_URL = 'https://bit.ly/3R4S4M0';
export const SERVICE_CONSENT_URL = 'https://bit.ly/3R4S4M0';

// 게임
export const CTA_ULR = 'https://mealion.notion.site/6929e003b398467c80da78cc516997b0?utm_source=web';
export const CTA_ULR_MOBILE = 'https://mealion.notion.site/6929e003b398467c80da78cc516997b0';
