import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../constants/urls';

export default function Download() {
  useEffect(() => {
    if (isIOS || isSafari) navigate(APP_STORE_URL);
    else navigate(GOOGLE_PLAY_URL);
  }, []);
  return (
    <div />
  );
}
